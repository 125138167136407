import { IUser } from "../models/iuser.model";
import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore } from "@angular/fire/firestore";
import { CollectionName } from "../utils/enums/collection-name.enum";
import { UserRole } from "../utils/enums/user-role.enum";
import * as firebase from "firebase/app";
import { AngularFireFunctions } from "@angular/fire/functions";
@Injectable({
  providedIn: "root",
})
export class WorkerService {
  public deviceName = "";
  //It add client to the database
  async addWorker(
    worker: IUser,
    companyId: string,
    token: string
  ): Promise<string> {
    try {
      let user = await this._angularFirestore
        .collection(CollectionName.USER)
        .add({
          userRole: UserRole.WORKER,
          ...worker,
          associatedCompany: [
            {
              id: companyId,
              role: UserRole.WORKER,
              isInvited: true,
              invitationalLink: token,
            },
          ],
        });
      return user.id;
    } catch (error) {
      throw error;
    }
  }

  /**
   * It add IUser to the database
   * @param workerData
   */
  async addWorkerByEmailAndPassword(
    workerData: IUser,
    token: string
  ): Promise<boolean> {
    try {
      let uid = await (
        await this._angularFireAuth.createUserWithEmailAndPassword(
          workerData.email,
          workerData.password
        )
      ).user.uid;
      let oldData = await this.getTokenPresent(token);
      await this._angularFirestore
        .collection(CollectionName.USER)
        .doc(oldData.$key)
        .delete();
      await this._angularFirestore
        .collection(CollectionName.USER)
        .doc(uid)
        .set({
          isInvited: false,
          userRole: UserRole.WORKER,
          companyId: oldData.companyId,
        });

      return true;
    } catch (error) {
      throw error;
    }
  }

  //It add client to the database
  async createWorker(uid: string, token: string): Promise<boolean> {
    try {
      let oldData = await this.getTokenPresent(token);
      await this._angularFirestore
        .collection(CollectionName.USER)
        .doc(oldData.$key)
        .delete();
      delete oldData.$key;
      delete oldData.invitationalLink;
      oldData.isInvited = false;
      await this._angularFirestore
        .collection(CollectionName.USER)
        .doc(uid)
        .set(oldData);
      return true;
    } catch (error) {
      throw error;
    }
  }
  /**
   * It check if token is present in databse or not
   * @param token
   */
  async getTokenPresent(token: string): Promise<any> {
    try {
      let docs = await this._angularFirestore
        .collection(CollectionName.USER)
        .ref.where("invitationalLink", "==", token)
        .get();
      console.log(docs.docs[0].data());
      if (docs.size) {
        return { ...docs.docs[0].data(), $key: docs.docs[0].id };
      } else {
        throw "Not found";
      }
    } catch (error) {
      throw error;
    }
  }
  //It add client to the database
  async createWorkerWithData(worker: IUser, uid: string): Promise<boolean> {
    try {
      await this._angularFirestore
        .collection(CollectionName.USER)
        .doc(uid)
        .set({
          userRole: UserRole.WORKER,
          ...worker,
        });
      return true;
    } catch (error) {
      throw error;
    }
  }
  //It delete worker from the database
  async deleteWorker(uid: string): Promise<boolean> {
    try {
      await this._angularFirestore
        .collection(CollectionName.USER)
        .doc(uid)
        .delete();
      return true;
    } catch (error) {
      throw error;
    }
  }
  //It update worker from the database
  async updateWorker(uid: string, data): Promise<boolean> {
    try {
      await this._angularFirestore
        .collection(CollectionName.USER)
        .doc(uid)
        .update(data);
      return true;
    } catch (error) {
      throw error;
    }
  }
  // It fetch all the list of clients
  async listAllActiveWorker(companyId: string): Promise<IUser[]> {
    try {
      let workerSnaps = await this._angularFirestore
        .collection(CollectionName.USER)
        .get()
        .toPromise();
      let allWorkers: IUser[] = [];
      workerSnaps.docs.map((_worker) => {
        if (_worker.data().associatedCompany) {
          let company = _worker
            .data()
            .associatedCompany.find((_company) => _company.id == companyId);
          if (company && !company.isInvited) {
            let data = _worker.data();
            data.selectedCompany = company;
            data.associatedCompany = [company];
            allWorkers.push({
              ...data,
              $key: _worker.id,
            });
          }
        }
      });
      return allWorkers;
    } catch (error) {
      throw error;
    }
  }
  // It fetch all the list of clients
  async listAllActiveAdmins(companyId: string): Promise<IUser[]> {
    try {
      let workerSnaps = await this._angularFirestore
        .collection(CollectionName.USER)
        .get()
        .toPromise();
      let allWorkers: IUser[] = [];
      workerSnaps.docs.map((_worker) => {
        if (_worker.data().associatedCompany) {
          let company = _worker
            .data()
            .associatedCompany.find((_company) => _company.id == companyId);
          if (
            company &&
            !company.isInvited &&
            company.role === UserRole.ADMIN
          ) {
            let data = _worker.data();
            data.selectedCompany = company;
            data.associatedCompany = [company];
            allWorkers.push({
              ...data,
              $key: _worker.id,
            });
          }
        }
      });
      return allWorkers;
    } catch (error) {
      throw error;
    }
  }
  // It fetch all the list of clients
  async listAllWorker(companyId: string): Promise<IUser[]> {
    try {
      let workerSnaps = await this._angularFirestore
        .collection(CollectionName.USER)
        .get()
        .toPromise();
      let allWorkers: IUser[] = [];
      workerSnaps.docs.map((_worker) => {
        if (Array.isArray(_worker.data().associatedCompany)) {
          let company = _worker
            .data()
            .associatedCompany.find((_company) => _company.id == companyId);
          if (company) {
            let data = _worker.data();
            data.selectedCompany = company;
            allWorkers.push({
              ...data,
              $key: _worker.id,
            });
          }
        }
      });
      return allWorkers;
    } catch (error) {
      throw error;
    }
  }
  /**
   * It get worker by email
   * @param email
   */
  async getIUserByEmail(email: string): Promise<IUser> {
    try {
      let workerSnaps = await this._angularFirestore
        .collection(CollectionName.USER)
        .ref.where("userRole", "==", UserRole.WORKER)
        .where("email", "==", email)
        .get();
      if (workerSnaps.empty) {
        throw { message: "You are not valid user" };
      } else {
        return {
          ...workerSnaps.docs[0].data(),
          $key: workerSnaps.docs[0].id,
        } as IUser;
      }
    } catch (error) {
      throw error;
    }
  }
  /**
   * It get user by email
   * @param email
   */
  async getUserByUid(uid: string): Promise<IUser> {
    try {
      let workerSnaps = await this._angularFirestore
        .collection(CollectionName.USER)
        .doc(uid)
        .get()
        .toPromise();
      if (workerSnaps.exists) {
        return {
          ...workerSnaps.data(),
          $key: workerSnaps.id,
        } as IUser;
      } else {
        return null;
      }
    } catch (error) {
      throw error;
    }
  }
  /**
   * It get user by email
   * @param email
   */
  async getUserByUidBool(uid: string): Promise<boolean> {
    try {
      let workerSnap = await this._angularFirestore
        .collection(CollectionName.USER)
        .doc(uid)
        .get()
        .toPromise();
      if (workerSnap.exists) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      throw error;
    }
  }
  /**
   * It get user by email
   * @param email
   */
  async isEmailAlreadyExists(email: string): Promise<boolean> {
    try {
      let workerSnaps = await this._angularFirestore
        .collection(CollectionName.USER)
        .ref.where("email", "==", email)
        .get();
      if (workerSnaps.empty) {
        return false;
      } else {
        return true;
      }
    } catch (error) {
      throw error;
    }
  }
  /**
   * It request to the http function to get user
   * @param uid
   */
  async getUser(uid: string): Promise<boolean> {
    try {
      return await this._angularFireFunctions
        .httpsCallable("getSingleUser")({ uid })
        .toPromise();
    } catch (error) {
      return error;
    }
  }

  /**
   * It set user to the database
   * @param user
   * @param docId
   */
  async setUser(user: IUser, docId: string) {
    try {
      console.log(user);
      await this._angularFirestore
        .collection(CollectionName.USER)
        .doc(docId)
        .set(user);
      return true;
    } catch (error) {
      throw error;
    }
  }

  async getAllUsers() {
    try {
      let workerSnaps = await this._angularFirestore
        .collection(CollectionName.USER)
        .get()
        .toPromise();
      let allWorkers: IUser[] = [];
      workerSnaps.docs.map((_worker) => {
        if (_worker) {
          allWorkers.push({
            ..._worker.data(),
            $key: _worker.id,
          });
        }
      });
      return allWorkers;
    } catch (error) {
      throw error;
    }
  }

  constructor(
    private _angularFireAuth: AngularFireAuth,
    private _angularFirestore: AngularFirestore,
    private _angularFireFunctions: AngularFireFunctions
  ) {}
}
