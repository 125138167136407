import { LoaderService } from "./../services/loader.service";
import { Client } from "./../models/client.model";
import { ClientService } from "./../services/client.service";
import { IUser, AssociatedCompany } from "./../models/iuser.model";
import { StateManagementService } from "./../services/state-management.service";
import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { Employee } from "../models/worker.model";
import { WorkerService } from "../services/worker.service";
import { UserRole } from "../utils/enums/user-role.enum";
import { AngularFireFunctions } from "@angular/fire/functions";
import { NgxPendoService } from "ngx-pendo";

@Injectable({
  providedIn: "root",
})
export class UserGuard implements CanActivate {
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return new Observable((observer) => {
      this._loaderService.start();
      this._auth.authState.subscribe(async (user) => {
        if (user) {
          let worker = await this._workerService.getUserByUid(user.uid);
          if (worker) {
            console.log(worker);
            await this.setDataToState(worker);
            observer.next(true);
            observer.complete();
            this._loaderService.stop();
          } else {
            this._auth.signOut();
            this._loaderService.stop();
            this._router.navigate(["/auth"]);
            window.location.reload();
            observer.next(false);
            observer.complete();
          }
        } else {
          this._auth.signOut();
          this._router.navigate(["/auth"]);
          this._loaderService.stop();
          window.location.reload();
          observer.next(false);
          observer.complete();
        }
      });
    });
  }

  /**
   * It set data of logged in user
   */
  async setDataToState(worker: IUser) {
    try {
      let allPromises: Promise<AssociatedCompany>[] = [];
      worker.associatedCompany.map((_company) => {
        allPromises.push(this.getCompany(_company));
      });
      let allCompanies = await Promise.all(allPromises);
      let company = allCompanies.find(
        (_company) =>
          _company.role == UserRole.ADMIN && _company.isInvited == false
      );
      this._stateManagementService.setLoggedInfo({
        user: worker,
        companies: allCompanies,
        company: company,
      });
      if (company) {
        this.ngxPendoService.initialize(
          {
            id: worker.$key,
            name: worker.fname + " " + worker.lname,
            email: worker.email,
            role: worker.userRole,
          },
          {
            id: company.id,
            name: company.client.companyName,
          }
        );
      }

      // if (company) {
      //   console.log(
      //     await this._angularFireFunctions
      //       .httpsCallable("getSubscriptionDetail")({
      //         subscriptionId: company.client.subscriptionId,
      //       })
      //       .toPromise()
      //   );
      // }
      if (company && company.client.stepper) {
        this._router.navigate([`/sign-up/${company.id}`]);
      }
      return true;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  /**
   * It get company from the database
   * @param _company
   */
  getCompany(_company: AssociatedCompany): Promise<AssociatedCompany> {
    return new Promise(async (resolve, reject) => {
      try {
        _company.client = await this._clientService.getClient(_company.id);
        resolve(_company);
      } catch (error) {
        return _company;
      }
    });
  }
  constructor(
    private _router: Router,
    private _auth: AngularFireAuth,
    private _workerService: WorkerService,
    private _stateManagementService: StateManagementService,
    private _clientService: ClientService,
    private _loaderService: LoaderService,
    private _angularFireFunctions: AngularFireFunctions,
    protected ngxPendoService: NgxPendoService
  ) {}
}
