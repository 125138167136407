import { AssociatedCompany, IUser } from "./../models/iuser.model";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class StateManagementService {
  public loggedInAdminInfo: {
    user: IUser;
    company: AssociatedCompany;
    companies: AssociatedCompany[];
  };

  /**
   * It set logged in admin and company info
   * @param data
   */
  setLoggedInfo(data) {
    this.loggedInAdminInfo = data;
    console.log(data);
  }
  constructor() {}
}
