import { map } from "rxjs/operators";
import { TempClient } from "./../models/tempClient.model";
import { UserRole } from "./../utils/enums/user-role.enum";
import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore } from "@angular/fire/firestore";
import { Client } from "../models/client.model";
import { CollectionName } from "../utils/enums/collection-name.enum";
import { IUser } from "../models/iuser.model";

@Injectable({
  providedIn: "root",
})
export class ClientService {
  //It add client to the database
  async addClient(client: Client): Promise<boolean> {
    try {
      let companyId = await (
        await this._angularFirestore.collection(CollectionName.CLIENT).add({
          companyName: client.companyName,
          createdDate: new Date(),
          pricePlan: client.pricePlan,
          firstName: client.firstName,
          email: client.email,
          lastName: client.lastName,
          numberOfUsers: client.numberOfUsers,
        })
      ).id;
      let userId = await (
        await this._angularFireAuth.createUserWithEmailAndPassword(
          client.email,
          client.password
        )
      ).user.uid;
      await this._angularFirestore
        .collection(CollectionName.USER)
        .doc(userId)
        .set({
          userRole: UserRole.ADMIN,
          companyId,
          email: client.email,
        });
      await this._angularFireAuth.signOut();
      return true;
    } catch (error) {
      throw error;
    }
  }
  //It add client to the database
  async addClientUsingGmail(client: Client, userId: string): Promise<boolean> {
    try {
      let companyId = await (
        await this._angularFirestore.collection(CollectionName.CLIENT).add({
          ...client,
          createdDate: new Date(),
        })
      ).id;
      await this._angularFirestore
        .collection(CollectionName.USER)
        .doc(userId)
        .set({
          userRole: UserRole.ADMIN,
          companyId,
        });
      alert("Company is created successfully");
      await this._angularFireAuth.signOut();
      return true;
    } catch (error) {
      throw error;
    }
  }
  /**
   * It get client using clientID
   * @param clientId
   */
  async getClient(clientId: string): Promise<Client> {
    try {
      let clientSnap = await this._angularFirestore
        .collection(CollectionName.CLIENT)
        .doc(clientId)
        .get()
        .toPromise();
      return {
        ...clientSnap.data(),
        $key: clientSnap.id,
      } as Client;
    } catch (error) {
      throw error;
    }
  }
  /**
   * It get client using domain name
   * @param clientId
   */
  async getClientByDomain(companyDomain: string): Promise<Client[]> {
    try {
      let clientSnaps = await this._angularFirestore
        .collection(CollectionName.CLIENT, ref => ref.where("companyDomain", "==", companyDomain))
        .get()
        .toPromise();
      return clientSnaps.docs.map(clientSnap => {
        return {
          ...clientSnap.data(),
          $key: clientSnap.id,
        } as Client;
      });
    } catch (error) {
      throw error;
    }
  }

  /**
   * It update the client
   * @param clientId
   * @param data
   */
  async updateClient(clientId: string, data: any): Promise<boolean> {
    try {
      await this._angularFirestore
        .collection(CollectionName.CLIENT)
        .doc(clientId)
        .update(data);
      return true;
    } catch (error) {
      throw error;
    }
  }
  // It fetch all the list of clients
  // async listAllClient(): Promise<Client[]> {
  //   try {
  //     let clientSnaps = await this._angularFirestore
  //       .collection(CollectionName.CLIENT)
  //       .get()
  //       .toPromise();
  //     const clientObjects = [];
  //     return clientSnaps.docs.map((_client) => {
  //       if (_client.data().hasOwnProperty('companyDeleted')) {
  //         return {
  //           ..._client.data(),
  //           $key: _client.id,
  //         } as Client;
  //       }
  //     });
  //   } catch (error) {
  //     throw error;
  //   }
  // }

  async listAllClient() {
    try {
      return await this._angularFirestore
        .collection(CollectionName.CLIENT)
        .get()
        .toPromise().then((clients) => {
          const clientObjects = [];
          clients.docs.map((_client) => {
            if (_client.data().hasOwnProperty('companyDeleted')) {
              console.log(_client.id);
              if (!_client.data().companyDeleted) { //if companydeleted false 
                clientObjects.push({
                  ..._client.data(),
                  $key: _client.id
                });
              }
            }
            else {
              clientObjects.push({
                ..._client.data(),
                $key: _client.id
              });
            }

          });
          return clientObjects;
        });
    }
    catch (error) {
      throw error;
    }
  }



  /**
   * It create client
   * @param data
   */
  async createClient(data: any): Promise<string> {
    try {
      let clientSnap = await this._angularFirestore
        .collection(CollectionName.CLIENT)
        .add(data);
      return clientSnap.id;
    } catch (error) {
      throw error;
    }
  }
  /**
   * It get client
   * @param uid
   */
  async viewClient(docId: string): Promise<Client> {
    return this._angularFirestore
      .collection(CollectionName.CLIENT)
      .doc(docId)
      .get()
      .pipe(
        map((snap) => {
          if (snap.exists) {
            return {
              ...snap.data(),
              id: snap.id,
            } as IUser;
          } else {
            return null;
          }
        })
      )
      .toPromise();
  }
  /**
   * It create admin of the company
   * @param admin
   * @param uid
   * @param companyId
   */
  async createAdmin(admin: TempClient, uid: string, companyId: string) {
    return this._angularFirestore
      .collection(CollectionName.USER)
      .doc(uid)
      .set({
        email: admin.email,
        fname: admin.fname,
        lname: admin.lname,
        associatedCompany: [
          { id: companyId, role: UserRole.ADMIN, isInvited: false },
        ],
      });
  }

  /**
   * It update user
   * @param uid
   * @param data
   */
  async updateUser(uid: string, data: IUser) {
    return this._angularFirestore
      .collection(CollectionName.USER)
      .doc(uid)
      .update(data);
  }
  /**
   * It get user
   * @param uid
   */
  async getUser(uid: string): Promise<IUser> {
    return this._angularFirestore
      .collection(CollectionName.USER)
      .doc(uid)
      .get()
      .pipe(
        map((snap) => {
          if (snap.exists) {
            return {
              ...snap.data(),
              id: snap.id,
            } as IUser;
          } else {
            return null;
          }
        })
      )
      .toPromise();
  }
  constructor(
    private _angularFireAuth: AngularFireAuth,
    private _angularFirestore: AngularFirestore
  ) { }
}
