import { Injectable } from "@angular/core";
import { NgxUiLoaderService } from "ngx-ui-loader";

@Injectable({
  providedIn: "root",
})
export class LoaderService {
  start() {
    this.ngxService.start();
  }
  stop() {
    this.ngxService.stop();
  }
  constructor(private ngxService: NgxUiLoaderService) {}
}
