import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { Device } from "../models/devices.model";
import { CollectionName } from "../utils/enums/collection-name.enum";

@Injectable({
  providedIn: "root",
})
export class IpService {
  /**
   * It returns current Ip Address
   * Note: Here we are using 3rd part api to get current ip address
   */
  async getIp(): Promise<any> {
    try {
      let ipData = (await this._httpClient
        .get("https://pro.ip-api.com/json?key=Z5M1S13y4oSwJ9P")
        .toPromise()) as any;
      return {
        ip: ipData.query,
        country: ipData.country,
        city: ipData.city,
      };
    } catch (error) {
      return "";
    }
  }

  /**
   * It detect ip
   */
  async isNewIp(uid: string): Promise<boolean> {
    try {
      let devices = await this.getDevices(uid);
      let ipInfo = await this.getIp();
      if (ipInfo !== '') {
        if (devices.length) {
          return devices.find((_device) => _device.ip == ipInfo.ip)
            ? false
            : true;
        }
      }
      else {
        throw Error("Issue with grabbing IP");
      }
    } catch (error) {
      throw error;
    }
  }
  /**
   * It get all the devices
   * @param uid
   */
  async getDevices(uid: string): Promise<Device[]> {
    try {
      let devicesSnaps = await this._angularFirestore
        .collection(CollectionName.USER)
        .doc(uid)
        .collection(CollectionName.DEVICE)
        .get()
        .toPromise();
      return devicesSnaps.docs.map((_device) => {
        return {
          ..._device.data(),
          $key: _device.id,
        } as Device;
      });
    } catch (error) {
      throw error;
    }
  }

  constructor(
    private _httpClient: HttpClient,
    private _angularFirestore: AngularFirestore
  ) { }
}
