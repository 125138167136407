import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "filterWordpressImage",
})
export class FilterWordpressImagePipe implements PipeTransform {
  transform(value: any, ...args: unknown[]): unknown {
    return value.sourceUrl;
  }
}
