import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { WorkerService } from "../services/worker.service";

@Injectable({
  providedIn: "root",
})
export class HomeBetaGuard implements CanActivate {
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return new Observable((observer) => {
      this._auth.authState.subscribe(async (user) => {
        if (user) {
          try {
            if (user) {
              let worker = await this._workerService.getUserByUid(user.uid);

              if (worker) {
                this._router.navigate(["/dashboard"]);

                observer.next(false);
                observer.complete();
              } else {
                throw Error("Something goes wrong");
              }
            } else {
              throw {
                message: "User is not exists",
              };
            }
          } catch (error) {
            observer.next(true);
            observer.complete();
          }
        } else {
          observer.next(true);
          observer.complete();
        }
      });
    });
  }
  constructor(
    private _auth: AngularFireAuth,
    private _workerService: WorkerService,
    private _router: Router
  ) {}
}
