import { AngularFireFunctions } from "@angular/fire/functions";
import { environment } from "./../../environments/environment";
import { Injectable } from "@angular/core";
import { MsalService } from "@azure/msal-angular";
import { Client } from "@microsoft/microsoft-graph-client";
import { AlertsService } from "./alerts.service";
import { User } from "./user";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  public authenticated: boolean;
  public user: User;

  // <constructorSnippet>
  constructor(
    private msalService: MsalService,
    private alertsService: AlertsService,
    private _angularFireFunctions: AngularFireFunctions
  ) {
    this.authenticated = this.msalService.getAccount() != null;
    this.getUser().then((user) => {
      this.user = user;
    });
  }
  async signIn(): Promise<User> {
    let result = await this.msalService
      .loginPopup(OAuthSettings)
      .catch((reason) => {
        this.alertsService.add("Login failed", JSON.stringify(reason, null, 2));
      });

    if (result) {
      this.authenticated = true;
      this.user = await this.getUser();
    }
    return this.user;
  }

  // Sign out
  signOut(): void {
    this.msalService.logout();
    this.user = null;
    this.authenticated = false;
    return;
  }

  // Silently request an access token
  async getAccessToken(): Promise<string> {
    let result = await this.msalService
      .acquireTokenSilent(OAuthSettings)
      .catch((reason) => {
        this.alertsService.add(
          "Get token failed",
          JSON.stringify(reason, null, 2)
        );
      });

    if (result) {
      return result.accessToken;
    }
    return null;
  }

  /**
   * It create user using email and password
   * @param data
   */
  createAuthUser(data: any) {
    return this._angularFireFunctions
      .httpsCallable("createAuthUser")(data)
      .toPromise();
  }
  // <getUserSnippet>
  private async getUser(): Promise<User> {
    if (!this.authenticated) return null;

    let graphClient = Client.init({
      authProvider: async (done) => {
        let token = await this.getAccessToken().catch((reason) => {
          done(reason, null);
        });
        if (token) {
          done(null, token);
        } else {
          done("Could not get an access token", null);
        }
        console.log(token);
      },
    });

    // Get the user from Graph (GET /me)
    let graphUser = await graphClient.api("/me").get();
    console.log(graphUser);
    let user = new User();
    user.displayName = graphUser.displayName;
    // Prefer the mail property, but fall back to userPrincipalName
    user.email = graphUser.mail || graphUser.userPrincipalName;
    user.uid = graphUser.id;

    return user;
  }
  // </getUserSnippet>
}
export const OAuthSettings = environment.microsoft;
