import { ReactiveFormsModule } from "@angular/forms";
import { FormsModule } from "@angular/forms";
import { CustompipeModule } from "./pipes/custompipe.module";
import { IpService } from "./services/ip.service";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { environment } from "src/environments/environment";
import { AngularFireModule } from "@angular/fire";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule } from "@angular/common/http";
import { OAuthSettings } from "./services/auth.service";
import { MsalModule } from "@azure/msal-angular";
import { NgxUiLoaderModule } from "ngx-ui-loader";
import { AgmCoreModule } from "@agm/core";
import { AngularFireStorageModule } from "@angular/fire/storage";
import { NgxPendoModule } from "ngx-pendo";
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MsalModule.forRoot({
      auth: {
        clientId: OAuthSettings.appId,
        redirectUri: OAuthSettings.redirectUri,
        authority: "https://login.microsoftonline.com/common",
      },
    }),
    AngularFireStorageModule,
    AgmCoreModule.forRoot({
      apiKey: environment.gapi,
    }),
    NgxPendoModule.forRoot({
      pendoApiKey: environment.pendoApi,
      pendoIdFormatter: (value: any) => value.toString().toLowerCase(),
    }),
    NgxUiLoaderModule,
    CustompipeModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [IpService],
  bootstrap: [AppComponent],
})
export class AppModule {}
