import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "search",
})
export class SearchPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (!value) return null;
    if (!args) return value;
    args = args.trim().toLowerCase();
    return value.filter(function (item) {
      return JSON.stringify(item)
        .replace(/\s+/g, " ")
        .trim()
        .toLowerCase()
        .includes(args);
    });
  }
}
