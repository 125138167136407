// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyDPivc1G3ofOLiuCPnFwjqiu5alV2YBgl4",
    authDomain: "auth.klokka.com",
    databaseURL: "https://klokka-webo.firebaseio.com",
    projectId: "klokka-webo",
    storageBucket: "klokka-webo.appspot.com",
    messagingSenderId: "375712184542",
    appId: "1:375712184542:web:e85b557408135f42ee6a4f",
    measurementId: "G-16DEEC88FY",
  },
  gapi: "AIzaSyB1Q0PkvzF4MzVZDbBHjqPytZDV4IsF5sY",
  microsoft: {
    appId: "ed947381-e318-4ded-8bc3-0ef55ae09547",
    scopes: ["User.Read", "Directory.Read.All"],
    validateAuthority: true,
    redirectUri: "http://localhost:4200",
    postLogoutRedirectUri: "https://app.klokka.com",
    navigateToLoginRequestUrl: true,
  },
  gSuit: {
    apiKey: "AIzaSyBK4ENkmR1iv_WkJOflJLJI_2G8mnUzRQ0",
    scope: "https://www.googleapis.com/auth/admin.directory.user.readonly",
    discoveryDocs: [
      "https://www.googleapis.com/discovery/v1/apis/admin/directory_v1/rest",
    ],
    clientId:
      "375712184542-9ried1qolmor0132siqdj30g6jlh6k2h.apps.googleusercontent.com",
  },
  cryptoKey: "0123456789123456",
  domainName: "http://localhost:4200",
  websiteUrl: "https://klokka.com",
  dynamicApiUrl:
    "https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyDPivc1G3ofOLiuCPnFwjqiu5alV2YBgl4",
  shortifySubDomain: "https://url.klokka.com",
  pendoApi: "0db9c245-bdf8-41ce-73a7-adfb1a9bebd8",
};

// export const environment = {
//   production: false,
//   firebase: {
//     apiKey: "AIzaSyB2e4RKWAJvVhgJPaL56CVQA4AcL3t7N1Y",
//     authDomain: "auth.klokka.dev",
//     projectId: "klokka-development",
//     storageBucket: "klokka-development.appspot.com",
//     messagingSenderId: "72624745777",
//     appId: "1:72624745777:web:b519fb16be9e6310939410",
//     measurementId: "G-ZNPRXY1MRF",
//   },
//   gapi: "AIzaSyB1Q0PkvzF4MzVZDbBHjqPytZDV4IsF5sY",
//   microsoft: {
//     appId: "ed947381-e318-4ded-8bc3-0ef55ae09547",
//     scopes: ["User.Read", "Directory.Read.All"],
//     validateAuthority: true,
//     redirectUri: "http://localhost:4200",
//     postLogoutRedirectUri: "https://klokka.dev",
//     navigateToLoginRequestUrl: true,
//   },
//   gSuit: {
//     apiKey: "AIzaSyBK4ENkmR1iv_WkJOflJLJI_2G8mnUzRQ0",
//     scope: "https://www.googleapis.com/auth/admin.directory.user.readonly",
//     discoveryDocs: [
//       "https://www.googleapis.com/discovery/v1/apis/admin/directory_v1/rest",
//     ],
//     clientId:
//       "375712184542-9ried1qolmor0132siqdj30g6jlh6k2h.apps.googleusercontent.com",
//   },
//   cryptoKey: "0123456789123456",
//   domainName: "http://localhost:4200",
//   dynamicApiUrl:
//     "https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyB2e4RKWAJvVhgJPaL56CVQA4AcL3t7N1Y",
//   shortifySubDomain: "https://url.klokka.dev",
//   pendoApi: "0db9c245-bdf8-41ce-73a7-adfb1a9bebd8",
// };
