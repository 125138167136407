import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SearchPipe } from "./search.pipe";
import { SafePipe } from "./safe.pipe";
import { SplitPipe } from "./split.pipe";
import { FilterWordpressImagePipe } from "./filter-wordpress-image.pipe";

@NgModule({
  declarations: [SearchPipe, SafePipe, SplitPipe, FilterWordpressImagePipe],
  imports: [CommonModule],
  exports: [SearchPipe, SafePipe, SplitPipe, FilterWordpressImagePipe],
})
export class CustompipeModule {}
