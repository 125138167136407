import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { UserGuard } from "./guards/user.guard";
import { HomeBetaGuard } from "./guards/home-beta.guard";

const routes: Routes = [
  {
    path: "",
    loadChildren: () =>
      import("./modules/front/front.module").then((m) => m.FrontModule),
    canActivate: [HomeBetaGuard],
  },
  {
    path: "",
    loadChildren: () =>
      import("./modules/dashboard/dashboard.module").then(
        (m) => m.UserDashboardModule
      ),
    canActivate: [UserGuard],
  },
  {
    path: "dashboard",
    loadChildren: () =>
      import("./modules/dashboard/dashboard.module").then(
        (m) => m.UserDashboardModule
      ),
    canActivate: [UserGuard],
  },
  {
    path: "superadmin",
    loadChildren: () =>
      import("./modules/super-dashboard/super-dashboard.module").then(
        (m) => m.SuperDashboardModule
      ),
    canActivate: [UserGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { anchorScrolling: "enabled" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
